import { MouseEvent, useRef } from 'react';

import cardCoursePromocodeButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_promocode_button_click';
import { translation, TranslatedComponent, format } from '@hh.ru/front-static-app';
import { Tag, TooltipHover } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import useSnackbar from 'src/hooks/useSnackbar';
import { CourseDto } from 'src/types/courses';

import styles from './styles.less';

const TrlKeys = {
    byPromocode: 'career-platform.components.promocode',
    snack: 'career-platform.components.snack',
    tooltip: 'career-platform.components.tooltip',
};

interface CardCoursePromocodeProps {
    course: CourseDto;
    discount: number;
    code?: string;
    showCopyIcon?: boolean;
}

const CardCoursePromocode: TranslatedComponent<CardCoursePromocodeProps> = ({ course, discount, code = '', trls }) => {
    const { showSnack, hideSnack } = useSnackbar();
    const activatorRef = useRef<HTMLDivElement | null>(null);

    const handleClickCopy = (e: MouseEvent<HTMLDivElement>) => {
        if (!code) {
            return;
        }
        e.stopPropagation();
        // ignore text selection
        if (window.getSelection()?.toString().length) {
            return;
        }
        void navigator.clipboard.writeText(code);
        cardCoursePromocodeButtonClick({
            courseName: course.name,
            providerId: course?.provider?.id || null,
            providerName: course?.provider?.name || null,
            cardType: course.purpose,
            professionId: course.profession?.id || null,
        });
        showSnack({
            children: format(trls[TrlKeys.snack], { '{0}': code }),
            onClose: hideSnack,
            showClose: true,
            addon: <CheckCircleFilledSize24 initial="positive" />,
            autohideTime: 3000,
        });
    };

    const discountValue = `–${discount}%`;

    return (
        <div className={styles.wrapper} onClick={handleClickCopy} ref={activatorRef}>
            <Tag style="positive">
                {code ? `${discountValue} ${trls[TrlKeys.byPromocode]} ${code}` : `${discountValue}`}
            </Tag>
            <TooltipHover placement="top-center" activatorRef={activatorRef}>
                {trls[TrlKeys.tooltip]}
            </TooltipHover>
        </div>
    );
};

export default translation(CardCoursePromocode);
