import { InputFormatter } from '@hh.ru/magritte-ui-input/types';

const phoneSerializer = {
    format(phone = '') {
        const match = phone.match(/\+7(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
        if (!match) {
            return '+7';
        }
        const [, a, b, c, d] = match;

        return ['+7', a, b, c, d].filter(Boolean).join(' ');
    },
    parse(text = '+7'): string {
        /**
         *последовательно удаляем первые "+"" и "7" на случай если между "+' и "7" ввели что-то
         *пример:
         *"+7"
         *ввели "1"
         *"+17" => после реплейсов будет "1"
         */
        const withoutprefix = text.replace('+', '').replace('7', '');
        const digits = withoutprefix.replaceAll(/\D+/g, '');
        return ['+7', ...digits].join('');
    },
};

export const phoneFormatter: InputFormatter = (prevValue, value = '', caretPosition) => {
    // input иногда просто хочет получить текущее значение без контекста интеракции с пользователем и ставит prevValue="", caretPosition=0
    // чтобы ничего не ломалось тут отдельно обработка этого кейса
    if (!prevValue) {
        return {
            outputValue: phoneSerializer.parse(value),
            formattedValue: phoneSerializer.format(value),
            caretPosition,
        };
    }
    const parsedValue = phoneSerializer.parse(value);
    const formattedValue = phoneSerializer.format(parsedValue);

    const formattedPrevValue = phoneSerializer.format(prevValue);
    const diff = formattedValue.length - formattedPrevValue.length;
    const bias = diff + 1;
    const caretPositionNew = caretPosition + bias;

    return {
        formattedValue: phoneSerializer.format(parsedValue),
        outputValue: parsedValue,
        caretPosition: caretPositionNew,
    };
};
