import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface FooterNavTitleProps {
    text: string;
}
const FooterNavTitle: FC<FooterNavTitleProps> = ({ text }) => (
    <div className={styles.footerNavTitle}>
        <Text typography="subtitle-1-semibold" Element="p">
            {text}
        </Text>
    </div>
);

export default FooterNavTitle;
