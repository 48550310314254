const endings = [2, 0, 1, 1, 1, 2];

const getEnding = (count: number): number => {
    const mod = count % 100;
    return mod < 20 && mod > 4 ? 2 : endings[Math.min(count % 10, 5)];
};

const pluralize = ({
    count,
    one,
    some,
    many,
    useCount = false,
}: {
    count: number;
    one: string;
    some: string;
    many: string;
    useCount?: boolean;
}): string => `${useCount ? `${count} ` : ''}${[one, some, many][getEnding(parseInt(`${count}`, 10))]}`;

export default pluralize;
