import { AxiosResponse } from 'axios';

import { fetcher } from '@hh.ru/front-static-app';

const URL = '/shards/calculator/subscribe';

export interface SubscribeToCourseBody {
    email: string;
    professionId: string;
    gradeId: string;
    skillId: number;
}

declare global {
    interface FetcherPostApi {
        [URL]: { body: SubscribeToCourseBody; response: Promise<AxiosResponse<unknown>> };
    }
}

export const postSubscribeToCourse = (
    data: SubscribeToCourseBody
): FetcherPostApi['/shards/calculator/subscribe']['response'] => fetcher.post(URL, data);
