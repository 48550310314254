import { CourseDto, CourseStatus, CourseTypeFollowLink, CourseTypeRequest, CourseTypeSubscribe } from './course';

export const isCourseTypeFollowLink = (course: CourseDto): course is CourseTypeFollowLink =>
    course.status === CourseStatus.READY;

export const isCourseTypeSubscribe = (course: CourseDto): course is CourseTypeSubscribe =>
    course.status === CourseStatus.PROGRESS;

export const isCourseTypeRequest = (course: CourseDto): course is CourseTypeRequest =>
    course.status === CourseStatus.REQUEST;
