import { CardStyle } from '@hh.ru/magritte-ui';

import { AreaId } from 'src/types/area';
import { Currency, DurationDto } from 'src/types/common';
import { ProfessionId, ProfessionName } from 'src/types/profession';
import { ProviderDto } from 'src/types/provider';
import { SkillDto } from 'src/types/skill';
import { StreamDto } from 'src/types/stream';

import { CourseId, CourseName } from 'src/types/courses/common';

export enum CoursePurpose {
    ALL = 'ALL',
    PROFESSION = 'PROFESSION',
    SKILL = 'SKILL',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    HIGHER_EDUCATION = 'HIGHER_EDUCATION',
}

export enum CourseStatus {
    READY = 'READY',
    PROGRESS = 'IN_PROGRESS',
    DRAFT = 'DRAFT',
    REQUEST = 'REQUEST',
}

export enum CourseFormat {
    ALL = 'ALL',
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
}

export enum CourseType {
    EXTERNAL = 'EXTERNAL',
    INTERNAL = 'INTERNAL',
}

export type CoursePurposeSpecific = Exclude<CoursePurpose, CoursePurpose.ALL>;

export type CourseFormatSpecific = Exclude<CourseFormat, CourseFormat.ALL>;

interface AdvertDto {
    ageLimit: number;
    link: string;
    advert: boolean;
}

interface PriceDto {
    fullAmount: number | null;
    discountAmount: number | null;
    amountPerMonth: number | null;
    currency: `${Currency}`;
    promotion: {
        discount: number;
        code: string;
    } | null;
}

interface LevelDto {
    from: string;
    to: string | null;
}

interface ProfessionDto {
    id: ProfessionId;
    name: ProfessionName;
}

export interface CourseAreaDto {
    id: AreaId;
    name: string;
}

interface CourseBaseDto {
    id: CourseId;
    status: `${CourseStatus}`;
    name: CourseName;
    description: string;
    color: string;
    format: `${CourseFormatSpecific}` | null;
    type: `${CourseType}` | null;
    purpose: `${CoursePurposeSpecific}`;
    stream: StreamDto;
    skill: SkillDto | null;
    level: LevelDto | null;
    duration: DurationDto | null;
    price: PriceDto | null;
    provider: ProviderDto | null;
    advert: AdvertDto | null;
    profession: ProfessionDto | null;
    style: CardStyle | null;
    areas: CourseAreaDto[];
}

export interface CourseTypeFollowLink extends CourseBaseDto {
    status: CourseStatus.READY;
    link: string;
}

export interface CourseTypeSubscribe extends CourseBaseDto {
    status: CourseStatus.PROGRESS;
}

export interface CourseTypeRequest extends CourseBaseDto {
    status: CourseStatus.REQUEST;
}

export type CourseDto = CourseTypeFollowLink | CourseTypeSubscribe | CourseTypeRequest;

export interface CourseListResDto {
    found: number;
    counter: {
        allCount: number;
        professionCount: number;
        skillCount: number;
    };
    items: CourseDto[];
    perPage: number;
    pages: number;
    page: number;
    purpose: CoursePurpose;
}

export interface CoursesSearchSuggestDto {
    token: string;
    match: number;
}
