export enum Currency {
    RUB = 'RUB',
    EUR = 'EUR',
    USD = 'USD',
    RUR = 'RUR',
}

export enum Period {
    YEARS = 'YEARS',
    MONTHS = 'MONTHS',
    WEEKS = 'WEEKS',
    DAYS = 'DAYS',
    HOURS = 'HOURS',
    MINUTE = 'MINUTE',
    SECOND = 'SECOND',
}

export interface SalaryDto {
    from: number;
    to: number;
    period: Period;
    currency: Currency;
}

export interface Icon {
    content: string;
    color: string;
}

export interface Redirect {
    link: string;
    placeholder: string;
}

export interface ExperienceDto {
    min: number;
    max: number;
}

export interface DurationDto {
    value: number;
    type: `${Period}`;
}

export type ResWithPaginationDto<T, K extends Record<string, unknown>> = {
    data: T[];
    found: number;
    page: number;
    pages: number;
    perPage: number;
} & K;

export type ReqWithPaginationDto<T extends Record<string, unknown>> = {
    page: number;
    perPage: number;
} & T;
