import { AreaId } from 'src/types/area';
import { ProfessionId, ProfessionName } from 'src/types/profession';
import { ProviderDto } from 'src/types/provider';
import { SkillDto } from 'src/types/skill';
import { StreamDto } from 'src/types/stream';

import { CourseAreaDto, CourseFormat, CoursePurpose } from 'src/types/courses';

export interface CourseFilterStateDto {
    state: CourseFilterCellStateDto;
}

interface CourseFilterCellStateDto {
    count: number;
    selected: boolean;
    disabled: boolean;
}

export interface CourseFilterDurationDto {
    id: number;
    durationAlias: string;
}

export enum CourseFilterAvailableSortDto {
    Relevant = 'RELEVANT',
    Default = 'DEFAULT',
    Short = 'SHORT',
    Long = 'LONG',
    Priceup = 'PRICE_UP',
    Pricedown = 'PRICE_DOWN',
}

interface CourseFilterPurposeDto extends CourseFilterStateDto {
    value: CoursePurpose;
}

interface CourseFilterStreamDto extends CourseFilterStateDto {
    stream: StreamDto;
}

interface CourseFilterSkillsDto extends CourseFilterStateDto {
    skill: SkillDto;
}

interface CourseFilterProvidersDto extends CourseFilterStateDto {
    provider: ProviderDto;
}

interface CourseFilterDurationsDto extends CourseFilterStateDto {
    duration: CourseFilterDurationDto;
}

interface CourseFilterProfessionsDto extends CourseFilterStateDto {
    profession: {
        id: ProfessionId;
        name: ProfessionName;
    };
}

interface CourseFilterFormatDto extends CourseFilterStateDto {
    value: CourseFormat;
}

interface CourseFilterAreasDto extends CourseFilterStateDto {
    area: CourseAreaDto;
}

export interface CourseFilterDto {
    filterPurposes: CourseFilterPurposeDto[];
    filterFormat: CourseFilterFormatDto[];
    filterAreas: CourseFilterAreasDto[];
    filterStreams: CourseFilterStreamDto[];
    filterSkills: CourseFilterSkillsDto[];
    filterProviders: CourseFilterProvidersDto[];
    filterDurations: CourseFilterDurationsDto[];
    filterProfessions: CourseFilterProfessionsDto[];
    availableSortValue: CourseFilterAvailableSortDto[];
}

export enum CourseFilterItemKey {
    FilterPurposes = 'filterPurposes',
    FilterFormat = 'filterFormat',
    FilterAreas = 'filterAreas',
    FilterStreams = 'filterStreams',
    FilterSkills = 'filterSkills',
    FilterProviders = 'filterProviders',
    FilterDurations = 'filterDurations',
    FilterProfessions = 'filterProfessions',
}

export type CourseFilterId = string;

export interface CourseFilterItem extends CourseFilterStateDto {
    id: CourseFilterId;
    value: string;
    name: string;
    icon?: string;
    type: CourseFilterItemKey;
}

export enum CourseFilterType {
    Radio = 'radio',
    Checkbox = 'checkbox',
}

export const CourseFilterItemKeyTypeMap: Record<CourseFilterItemKey, CourseFilterType> = {
    [CourseFilterItemKey.FilterPurposes]: CourseFilterType.Radio,
    [CourseFilterItemKey.FilterFormat]: CourseFilterType.Radio,
    [CourseFilterItemKey.FilterAreas]: CourseFilterType.Checkbox,
    [CourseFilterItemKey.FilterStreams]: CourseFilterType.Checkbox,
    [CourseFilterItemKey.FilterSkills]: CourseFilterType.Checkbox,
    [CourseFilterItemKey.FilterProviders]: CourseFilterType.Checkbox,
    [CourseFilterItemKey.FilterDurations]: CourseFilterType.Checkbox,
    [CourseFilterItemKey.FilterProfessions]: CourseFilterType.Checkbox,
} as const;

export const CourseFilterItemKeyHasSearch: Partial<Record<CourseFilterItemKey, boolean>> = {
    [CourseFilterItemKey.FilterProfessions]: true,
    [CourseFilterItemKey.FilterSkills]: true,
    [CourseFilterItemKey.FilterAreas]: true,
};

export type CourseFilter = Partial<Record<CourseFilterItemKey, CourseFilterItem[]>>;

export type CourseFilterSearch = string;

export interface CourseFilterQuery {
    search?: CourseFilterSearch;
    purpose?: CoursePurpose;
    format?: CourseFormat;
    areaIds?: AreaId[];
    professionIds?: string[];
    streamIds?: string[];
    skillIds?: string[];
    providerIds?: string[];
    duration?: string[];
    sort?: CourseFilterAvailableSortDto;
}

export interface CourseFilterQueryPaginated extends CourseFilterQuery {
    page: number;
}
