import { useCallback, useContext, useEffect, useId } from 'react';

import { SnackbarContext } from '@hh.ru/magritte-ui-snackbar/context';
import { SnackbarProps, SnackbarTransitionName } from '@hh.ru/magritte-ui-snackbar/types';

interface UseSnackbar {
    showSnack: (props: SnackbarProps) => void;
    hideSnack: () => void;
}

const useSnackbar = (): UseSnackbar => {
    const { animateIn, animateOut } = useContext(SnackbarContext);
    const snackId = useId();

    const hideSnack = useCallback(() => {
        animateOut(snackId, SnackbarTransitionName.Autohide);
    }, [animateOut, snackId]);

    const showSnack = useCallback(
        (props: SnackbarProps) => {
            animateIn(snackId, props);
        },
        [animateIn, snackId]
    );

    useEffect(() => {
        return () => {
            animateOut(snackId, SnackbarTransitionName.Autohide);
        };
    }, [snackId, animateOut]);

    return {
        showSnack,
        hideSnack,
    };
};

export default useSnackbar;
