import { FC, ReactNode } from 'react';

import styles from './styles.less';

interface FooterNavGroupProps {
    children: ReactNode;
}

const FooterNavGroup: FC<FooterNavGroupProps> = ({ children }) => (
    <div className={styles.footerNavGroup}>{children}</div>
);

export default FooterNavGroup;
