import { fetcher } from '@hh.ru/front-static-app';

import { CourseId } from 'src/types/courses';

const HOST = '/shards/courses/post_request_course';

interface FormData {
    phone: string;
    fullname: string;
}

declare global {
    interface FetcherPostApi {
        [HOST]: { response: void; body: { data: FormData; courseId: CourseId } };
    }
}

const postRequestCourse = async (courseId: CourseId, formData: FormData): Promise<void> => {
    try {
        const { data } = await fetcher.post<typeof HOST>(HOST, { data: formData, courseId });
        return data;
    } catch (e) {
        throw e;
    }
};

export default postRequestCourse;
