// Функция генерации data-qa, похожа на бэм с некоторыми оговорками
// используется для поддержания единого стиля data-qa во всем приложении
// Подробнее в README.md

const getDataQa = (
    block: string,
    element?: string | number | null,
    type?: string | number | null,
    state?: string | null
): string => {
    let result = `cp-${block}`;

    const concat = (separator: string, value: string | number) => {
        result = `${result}${separator}${value}`;
    };

    if (element) {
        concat('__', element);
    }
    if (type) {
        concat('--', type);
    }
    if (state) {
        concat('_', state);
    }

    return result;
};

export default getDataQa;
