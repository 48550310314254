import { FC, ReactNode } from 'react';

import styles from './styles.less';

interface FooterNavListProps {
    children: ReactNode;
}
const FooterNavList: FC<FooterNavListProps> = ({ children }) => <ul className={styles.footerNavList}>{children}</ul>;

export default FooterNavList;
