import { useCallback, useEffect, useRef, useState } from 'react';

// Хук который, делает setState только когда компонент смонтирован

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useSafeState: typeof useState = (initialState) => {
    const mountedRef = useRef(false);
    const [localState, setLocalState] = useState(initialState);

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const safeSetState: typeof setLocalState = useCallback(
        (...args) => mountedRef.current && setLocalState(...args),
        []
    );

    return [localState, safeSetState];
};

export default useSafeState;
