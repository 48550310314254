import { MouseEvent, useCallback } from 'react';

import cardCoursePromocodeButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/card_course_promocode_button_click';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { CopyOutlinedSize16, CheckOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import useSafeState from 'src/hooks/useSafeState';
import { CourseDto } from 'src/types/courses';

import styles from './styles.less';

const TrlKeys = {
    byPromocode: 'career-platform.components.promocode',
};

interface CardCoursePromocodeProps {
    discount: number;
    code?: string;
    showCopyIcon?: boolean;
    course: CourseDto;
}
const sleep = () => new Promise((resolve) => setTimeout(resolve, 1200));

const CardCoursePromocode: TranslatedComponent<CardCoursePromocodeProps> = ({
    trls,
    discount,
    code = '',
    showCopyIcon = true,
    course,
}) => {
    const [successCopy, setSuccessCopy] = useSafeState(false);

    const handleClickCopy = useCallback(
        async (e: MouseEvent<HTMLDivElement>) => {
            try {
                e.stopPropagation();
                // ignore text selection
                if (window.getSelection()?.toString().length) {
                    return;
                }
                void navigator.clipboard.writeText(code);

                cardCoursePromocodeButtonClick({
                    courseName: course.name,
                    providerId: course?.provider?.id || null,
                    providerName: course?.provider?.name || null,
                    cardType: course.purpose,
                    professionId: course.profession?.id || null,
                });

                setSuccessCopy(true);
                await sleep();
                setSuccessCopy(false);
                // eslint-disable-next-line no-empty
            } catch (ignore) {}
        },
        [code, course, setSuccessCopy]
    );

    const renderValue = () => (
        <>
            <span className={styles.discount}>–{discount}%</span>
            {code && (
                <>
                    &nbsp;
                    {code && trls[TrlKeys.byPromocode]}
                    &nbsp;
                    <span className={styles.code}>{code}</span>
                </>
            )}
        </>
    );

    return (
        <div className={styles.container}>
            <div className={styles.tag} onClick={code ? handleClickCopy : undefined}>
                <div className={styles.value}>{renderValue()}</div>
                {!!code && showCopyIcon && (
                    <div className={styles.copyIcon}>
                        {successCopy ? (
                            <CheckOutlinedSize16 initial="positive" />
                        ) : (
                            <CopyOutlinedSize16 initial="positive" />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default translation(CardCoursePromocode);
