import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, ButtonSize } from '@hh.ru/magritte-ui';

interface CardCourseRequestButtonProps {
    size?: ButtonSize;
    onClick: () => void;
}

const TrlKeys = {
    leaveRequest: 'career-platform.components.card-course.request-to-course.button.leaveRequest',
};

const CardCourseRequestButton: TranslatedComponent<CardCourseRequestButtonProps> = ({
    size = 'medium',
    onClick,
    trls,
}) => {
    return (
        <Button mode="primary" size={size} onClick={onClick} stretched>
            {trls[TrlKeys.leaveRequest]}
        </Button>
    );
};

export default translation(CardCourseRequestButton);
