import { MentorId } from 'src/types/mentor';
import { SkillId } from 'src/types/skill';

import serviceName from 'src/modules/serviceName';

export const MENTORS_EXTERNAL_LINK = `https://hh.ru/mentors?hhtmFrom=${serviceName}`;

export const getMentorExternalLink = (mentorId: MentorId): string =>
    `https://hh.ru/mentors/${mentorId}?hhtmFrom=${serviceName}`;

export const getAssessmentSkillLink = (skillId: SkillId): string =>
    `https://hh.ru//applicant/skills/${skillId}/verification_methods?hhtmFrom=${serviceName}`;

export const PRIVACY_POLICY_EXTERNAL_LINK = `https://hh.ru/article/31951?hhtmFrom=${serviceName}`;
export const PERSONAL_DATA_EXTERNAL_LINK = `https://hh.ru/article/personal_data?hhtmFrom=${serviceName}`;
export const CREATE_RESUME_EXTERNAL_LINK = `https://hh.ru/profile/resume/professional_role?hhtmFrom=${serviceName}`;
export const RESUME_EXTERNAL_LINK = `https://hh.ru/applicant/resumes?hhtmFrom=${serviceName}`;
export const ASSESSMENT_SKILLS_CATALOG_LINK = `https://hh.ru/applicant/skills/verification_methods?hhtmFrom=${serviceName}`;
export const ASSESSMENT_SKILL_BY_ID_LINK = `https://hh.ru/applicant/skills/verification_methods?hhtmFrom=${serviceName}`;
