import { FC } from 'react';
import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface Props {
    items: string[];
    color?: 'primary' | 'secondary' | 'tertiary';
    typography?: 'label-3-regular' | 'label-5-regular';
}

const CardCourseBreadcrumbsInfo: FC<Props> = ({ items, color = 'primary', typography = 'label-5-regular' }) => (
    <Text typography={typography}>
        <ul
            className={classnames(styles.list, {
                [styles[`list_color-${color}`]]: true,
            })}
        >
            {items.map((value, index) => (
                <li key={index} className={styles.item}>
                    {value}
                </li>
            ))}
        </ul>
    </Text>
);

export default CardCourseBreadcrumbsInfo;
