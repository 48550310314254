import { createRef, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { object, string } from 'yup';

import waitListCourseButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/wait_list_course_button_click';
import waitListCourseMailButtonClick from '@hh.ru/analytics-js-events/build/career_platform/courses/wait_list_course_mail_button_click';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { BottomSheet, Button, Input, Modal, Text, Title, useBreakpoint } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import { SubscribeToCourseBody, postSubscribeToCourse } from 'src/components/CardCourse/api/postSubscribeToCourse';
import FormDisclaimer from 'src/components/FormDisclaimer';
import useFormValidation from 'src/hooks/useFormValidation';
import useSnackbar from 'src/hooks/useSnackbar';
import { analytics, Goal } from 'src/utils/analytics';
import { EMAIL_REGEXP } from 'src/utils/regexp';

import styles from './styles.less';

const TrlKeys = {
    title: 'career-platform.subscribe-to-course.title',
    email: {
        placeholder: 'career-platform.subscribe-to-course.placeholder',
        required: 'career-platform.subscribe-to-course.required',
        pattern: 'career-platform.subscribe-to-course.pattern',
    },
    buttons: {
        leaveEmail: 'career-platform.subscribe-to-course.leaveEmail',
        close: 'career-platform.subscribe-to-course.close',
    },
    snack: {
        success: 'career-platform.subscribe-to-course.snack.success',
        error: 'career-platform.subscribe-to-course.snack.error',
    },
};

export interface CardCourseSubscribeProps {
    isVisible: boolean;
    onClose: () => void;
    professionId?: string;
    gradeId?: string;
    skillId?: number;
    dialogTitleText?: string;
    dialogSubtitleText?: string;
    dialogDescriptionText?: string;
    analyticsParams?: {
        gradeId?: string;
        professionId?: string;
        courseName?: string;
        skillId?: number;
        skillName?: string;
        providerId?: number;
        providerName?: string;
    };
}

type FormValues = SubscribeToCourseBody;

const CardCourseSubscribe: TranslatedComponent<CardCourseSubscribeProps> = ({
    trls,
    gradeId = '',
    professionId = '',
    skillId = 0,
    dialogTitleText,
    dialogSubtitleText,
    dialogDescriptionText,
    isVisible,
    onClose,
    analyticsParams = {
        gradeId: undefined,
        professionId: undefined,
        courseName: undefined,
        skillId: undefined,
        skillName: undefined,
        providerId: undefined,
        providerName: undefined,
    },
}) => {
    const ref = createRef<HTMLInputElement>();

    const { showSnack, hideSnack } = useSnackbar();
    const { onValidate, showErrorOnBlur } = useFormValidation(
        object({
            email: string().matches(EMAIL_REGEXP, trls[TrlKeys.email.pattern]).required(trls[TrlKeys.email.required]),
        })
    );
    const { isMobile } = useBreakpoint();

    const changedAnalyticsParams = {
        professionId,
        keySkillId: analyticsParams.skillId,
        keySkill: analyticsParams.skillName,
        courseName: analyticsParams.courseName,
        providerId: analyticsParams.providerId,
        providerName: analyticsParams.providerName,
    };

    const onSubmit = async (values: FormValues) => {
        try {
            await postSubscribeToCourse(values);
            analytics.reach(Goal.form_start_course_submit);
            waitListCourseMailButtonClick({
                mail: values.email,
                ...changedAnalyticsParams,
            });

            showSnack({
                children: trls[TrlKeys.snack.success],
                addon: <CheckCircleFilledSize24 initial="positive" />,
                onClose: hideSnack,
                showClose: true,
            });
        } catch (err) {
            showSnack({
                children: trls[TrlKeys.snack.error],
                addon: <MinusCircleFilledSize24 initial="negative" />,
                onClose: hideSnack,
                showClose: true,
            });
        } finally {
            onClose();
        }
    };

    useEffect(() => {
        if (!isVisible) {
            return () => undefined;
        }
        const id = setTimeout(() => {
            ref.current?.focus();
        }, 200);
        return () => {
            clearTimeout(id);
        };
    }, [isVisible, ref]);

    useEffect(() => {
        if (isVisible) {
            analytics.reach(Goal.form_start_course_click);
            waitListCourseButtonClick(changedAnalyticsParams);
        }
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const initialValues = useMemo(
        () => ({
            gradeId,
            professionId,
            skillId,
        }),
        [gradeId, professionId, skillId]
    );

    const renderForm = () => (
        <Form<FormValues>
            initialValues={initialValues}
            validate={onValidate}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name="email"
                        render={({ input, meta }) => (
                            <Input
                                placeholder={trls[TrlKeys.email.placeholder]}
                                {...input}
                                ref={ref}
                                autoComplete="true"
                                clearable
                                size="large"
                                disabled={submitting}
                                invalid={showErrorOnBlur(meta)}
                                errorMessage={meta.error as string}
                            />
                        )}
                    />
                    <div className={styles.disclaimer}>
                        <FormDisclaimer buttonName={trls[TrlKeys.buttons.leaveEmail]} />
                    </div>
                    <div className={styles.footer}>
                        <Button mode="tertiary" style="accent" onClick={onClose}>
                            {trls[TrlKeys.buttons.close]}
                        </Button>
                        <Button mode="primary" style="accent" disabled={submitting} loading={submitting} type="submit">
                            {trls[TrlKeys.buttons.leaveEmail]}
                        </Button>
                    </div>
                </form>
            )}
        />
    );

    const renderSubtitle = () => {
        return (
            <div className={styles.subtitle}>
                {isMobile && (
                    <>
                        <Text typography="subtitle-1-semibold">{dialogSubtitleText}</Text>
                        <Text Element="p" typography="paragraph-3-regular">
                            {dialogDescriptionText}
                        </Text>
                    </>
                )}

                {!isMobile && (
                    <Title size="small" Element="h3" description={dialogDescriptionText}>
                        {dialogSubtitleText}
                    </Title>
                )}
            </div>
        );
    };
    return (
        <>
            {isMobile && (
                <BottomSheet visible={isVisible} onClose={onClose}>
                    <Title Element="h3" size="large" description={renderSubtitle()}>
                        {dialogTitleText || trls[TrlKeys.title]}
                    </Title>
                    {renderForm()}
                </BottomSheet>
            )}
            {!isMobile && (
                <Modal visible={isVisible} title={dialogTitleText || trls[TrlKeys.title]} onClose={onClose}>
                    {renderSubtitle()}
                    {renderForm()}
                </Modal>
            )}
        </>
    );
};

export default translation(CardCourseSubscribe);
