import { translation, format, TranslatedComponent } from '@hh.ru/front-static-app';
import { Link, Text } from '@hh.ru/magritte-ui';

import { PERSONAL_DATA_EXTERNAL_LINK, PRIVACY_POLICY_EXTERNAL_LINK } from 'src/modules/externalLinks';

const TrlKeys = {
    1: 'career-platform.components.form-disclaimer.1',
    2: 'career-platform.components.form-disclaimer.2',
    3: 'career-platform.components.form-disclaimer.3',
    4: 'career-platform.components.form-disclaimer.4',
};

interface Props {
    buttonName: string;
}

const FormDisclaimer: TranslatedComponent<Props> = ({ buttonName, trls }) => (
    <div>
        <Text typography="label-4-regular" Element="span">
            {format(trls[TrlKeys[1]], {
                '{0}': buttonName,
            })}{' '}
        </Text>
        <Link
            typography="label-4-regular"
            href={PRIVACY_POLICY_EXTERNAL_LINK}
            target="_blank"
            rel="noreferrer noopener nofollow"
            inline
        >
            {trls[TrlKeys[2]]}
        </Link>
        <Text typography="label-4-regular" Element="span">
            {` ${trls[TrlKeys[3]]} `}
        </Text>
        <Link
            typography="label-4-regular"
            href={PERSONAL_DATA_EXTERNAL_LINK}
            target="_blank"
            rel="noreferrer noopener nofollow"
            inline
        >
            {trls[TrlKeys[4]]}
        </Link>
    </div>
);

export default translation(FormDisclaimer);
