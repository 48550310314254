import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, ButtonSize } from '@hh.ru/magritte-ui';

interface CardCourseSubscribeButtonProps {
    size?: ButtonSize;
    onClick: () => void;
}

const TrlKeys = {
    subscribe: 'career-platform.subscribe-to-course.subscribe',
};
const CardCourseSubscribeButton: TranslatedComponent<CardCourseSubscribeButtonProps> = ({
    size = 'medium',
    onClick,
    trls,
}) => {
    return (
        <Button style="neutral" mode="primary" size={size} onClick={onClick} stretched>
            {trls[TrlKeys.subscribe]}
        </Button>
    );
};

export default translation(CardCourseSubscribeButton);
