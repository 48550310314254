import { FC } from 'react';
import classnames from 'classnames';

import { Avatar, Text } from '@hh.ru/magritte-ui';

import getLinkWithoutTsPynRu from 'src/utils/getLinkWithoutTsPynRu';

import styles from './styles.less';

interface Props {
    name: string;
    iconSrc?: string;
    variant: 'primary' | 'secondary';
}

const CardCourseLogoPromoCompany: FC<Props> = ({ name, iconSrc, variant = 'primary' }) => {
    if (variant === 'primary') {
        return (
            <div className={styles.container}>
                {iconSrc && (
                    <img className={styles.icon} src={getLinkWithoutTsPynRu(iconSrc)} alt={name} loading="lazy" />
                )}
                <Text typography="paragraph-3-regular">{name}</Text>
            </div>
        );
    }

    return (
        <div className={classnames(styles.container, styles.containerSecondary)}>
            {iconSrc && (
                <Avatar size={24} mode="image" image={getLinkWithoutTsPynRu(iconSrc)} aria-label={name} shapeCircle />
            )}
            <Text typography="label-3-regular" style="secondary" maxLines={1}>
                {name}
            </Text>
        </div>
    );
};

export default CardCourseLogoPromoCompany;
