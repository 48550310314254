import { numberFormatter } from '@hh.ru/front-static-app';

import { Currency } from 'src/types/common';

import { NON_BREAKING_SPACE } from 'src/utils/characters';

type Value = number | string;
type ReturnValue = string;
type CurrencyType = `${Currency}`;

export const currencySymbols = {
    [Currency.RUB]: '₽',
    [Currency.RUR]: '₽',
    [Currency.EUR]: '€',
    [Currency.USD]: '$',
};

const getValue = (value: Value) => {
    return numberFormatter.format(value.toString(), { groupSeparator: NON_BREAKING_SPACE });
};

const getCurrencyType = (currencyType: CurrencyType = Currency.RUB): ReturnValue => {
    return currencySymbols[currencyType] || currencySymbols[Currency.RUB];
};

export const getCurrency = (value: Value, currencyType?: CurrencyType): ReturnValue => {
    return `${getValue(value)}${NON_BREAKING_SPACE}${getCurrencyType(currencyType)}`;
};

export const getCurrencyArray = (value: Value, currencyType?: CurrencyType): [ReturnValue, ReturnValue] => {
    return [getValue(value), getCurrencyType(currencyType)];
};

export const getCurrencyRange = (from: Value, to: Value, currencyType?: CurrencyType): ReturnValue => {
    return `${getValue(from)} – ${getValue(to)}${NON_BREAKING_SPACE}${getCurrencyType(currencyType)}`;
};

export const getCurrencyThousandthsRange = (from: Value, to: Value, currencyType?: CurrencyType): ReturnValue => {
    const range = [from.toString(), to.toString()];
    const currency = range.map((val) => numberFormatter.format(val.slice(0, val.length - 3)));
    // TODO: тыс
    return `${currency[0]}–${currency[1]} тыс.${NON_BREAKING_SPACE}${getCurrencyType(currencyType)}`;
};
